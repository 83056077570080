var exports = {};
Object.defineProperty(exports, "__esModule", {
  value: true
});

exports.warning = function () {};

exports.invariant = function () {};

export default exports;
export const __esModule = exports.__esModule,
      warning = exports.warning,
      invariant = exports.invariant;